import React, { useEffect, useState } from 'react';

import Card from '../components/card'
import Table from '../components/Table'
import Experience from "../components/experience";


const Main = () => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

     useEffect(() => {
         async function getData(){
             const response = await fetch(`https://j7mxr08ahd.execute-api.us-east-2.amazonaws.com/Prod/`)
             let actualData = await response.json();
             setData(actualData);
             setError(null);
             setLoading(false)
         }
         getData()
         }, []);

    return (
        <>
            {
                loading && <div>A moment please...</div>
            }
            {
                error && (<div>{`There is a problem fetching the data - ${error}`}</div>)
            }
            {
                data? <div className="col">
            <div className="row">
                <div className="col">
                    <Card info={data.card} />
                </div>
            </div>
            <div className="row">
                <h2 id='skills' className='mb-4'>Skills</h2>
                <div className="col">
                    <Table
                        key="Languages"
                        title="Languages"
                        headers={["Name", "Experience" ]}
                        rows={data.languages}
                    />
                </div>
                <div className="col">
                    <Table
                        title="Frameworks"
                        headers={["Name", "Language", "Experience" ]}
                        rows={data.frameworks}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                <Table
                    title="Databases"
                    headers={["Name", "Type", "Experience" ]}
                    rows={data.databases}
                />
                </div>
                <div className='col'>
                <Table
                    title="Platforms"
                    headers={["Name", "Experience" ]}
                    rows={data.platforms}
                />
                </div>
            </div>
            <div className='row'>
                <h2 id='exp' className='mb-4'>Experience</h2>
                <div className='col'>
                    <div className="d-flex flex-wrap justify-content-around">
                        {
                            data.experience_history.map(
                                ele => <Experience key={ele.name} experience={ele} />
                            )
                        }
                    </div>
                </div>
            </div>
        </div> : "No data"
            }
        </>
    )
}

export default Main;
