import React from "react";
import '../App.css';
import NavBar from './NavBar'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap'


function Layout({children}) {

  return (
    <>
    <NavBar />
    {children}
    </>
  );
}

export default Layout;
