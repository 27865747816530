import StringifyDateObject from './date'

/* eslint-disable jsx-a11y/anchor-is-valid */
const Experience = ({experience}) => {
    
    return (
        <div className="card p-3 mb-3 container-fluid" 
        // style={{width: "64rem"}}
        >
            <div className="card-body">
                <h5 className="card-title">
                    <strong>
                        {experience.name} 
                        <span className='fw-light mx-1'>|</span>
                        <span className='fw-light'>{experience.location.city}, {experience.location.state}</span>
                        <span className='fw-light mx-1'>|</span>
                        {experience.titles? experience.titles.join(', '): ""}
                    </strong>
                </h5>
                <h6 className="card-subtitle mb-2 text-body-secondary row">
                    <ul className="list-group list-group-flush col">
                        <li className="list-group-item">
                            <strong>Industry</strong>: {experience.industry}
                        </li>
                        <li className="list-group-item">
                            <p><StringifyDateObject date_range_object={experience} /></p>
                        </li>
                    </ul>
                </h6>
                <p className="card-text">{experience.description}</p>
                <div className='row'>
                    <div className='col'>
                        <p><span className='fw-bold'>Tech Stack:</span> {experience.tech_stack.join(', ')}</p>
                        <p><span className='fw-bold'>Platforms/Ops:</span> {experience.operations.join(', ')}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                    <ul className="list-group list-group-flush col">
                        {
                            experience.accomplishments.map(
                                ops => <li className="list-group-item">{ops}</li>
                            )
                        }
                    </ul>
                    </div>
                </div>
                {
                    experience.links.map(
                        link => <a href={link.url} className="card-link">{link.text}</a>
                    )
                }
            </div>
        </div>
    )
}

export default Experience