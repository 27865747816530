export default function Table({title, headers, rows}) {

    return (
        <>
            <h3>{title}</h3>
            <div className="table-responsive">
                <table className="table table-sm">
                    <thead>
                        <tr>{headers.map(header => <th key={header} scope="col">{header}</th>)}</tr>
                    </thead>
                    <tbody>
                        {
                            rows.map(
                                row => <tr>{
                                    Object.keys(row).map(
                                        key => (
                                            key === "name" ? <td key={row.icon_url}>
                                                <img
                                                    key={row.icon_url}
                                                    src={row.icon_url}
                                                    alt="logo" style={{width: "60px"}}
                                                    className="p-2"
                                                />
                                                {row[key]}
                                            </td> : key !== "icon_url" ? <td key={row[key]}>{row[key]}</td>: ''
                                        )
                                    )
                                }</tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}
