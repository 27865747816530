import Main from "./sections/main"
import Layout from "./components/Layout";


function App() {

  return (
    <Layout>
      <div className='container-fluid'>
        <Main/>
      </div>
    </Layout>
  );
}

export default App;
