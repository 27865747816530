const NavBar = () => {
    return (
        <nav id="navbar-example2" className="navbar sticky-top bg-body-tertiary px-3 mb-3">
            <a className="navbar-brand" href="/">DJC</a>
            <ul className="nav nav-pills">
                <li className="nav-item">
                    <a className="nav-link" href="#skills">Skills</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#exp">Experience</a>
                </li>
                {/* <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="/" role="button" aria-expanded="false">Dropdown</a>
                <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="#scrollspyHeading3">Third</a></li>
                    <li><a className="dropdown-item" href="#scrollspyHeading4">Fourth</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="#scrollspyHeading5">Fifth</a></li>
                </ul>
                </li> */}
            </ul>
        </nav>
    )
}

export default NavBar;
