const StringifyDateObject = ({date_range_object}) => {
    // let yoe = date_range_object.end_date.year - date_range_object.start_date.year
    // let moe = `${date_range_object.end_date.month - date_range_object.start_date.month} months`
    
    return <p>
        {date_range_object.start_date.month}/{date_range_object.start_date.year} - {date_range_object.end_date.month}/{date_range_object.end_date.year} <br/>
        {/* <strong>Period:</strong> {yoe > 1? `${yoe} years`:moe} */}
    </p>
};

export default StringifyDateObject;