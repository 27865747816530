export default function Card({ info }) {
    return (
        <div className="card mb-3">
        <div className="row g-0">
            <div className="col-md-4">
            <img src={info.profile_pic_link} className="img-fluid rounded-start" alt={info.title} />
            </div>
            <div className="col-md-8">
            <div className="card-body">
                <h5 className="card-title">{info.title}</h5>
                <h6 className="card-subtitle mb-2 text-body-secondary">
                    {info.subtitle}
                </h6>
                <p className="card-text">
                    {info.text}
                </p>
                {info.links? info.links.map(link => <a key={link.url}
                    href={link.url}
                    className="btn btn-lg btn-outline-primary card-link"
                    target='_blank'
                    rel="noreferrer"
                >
                     {link.text}
                 </a>):''}
            </div>
            </div>
        </div>
        </div>
    )
}